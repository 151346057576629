import React from 'react';
import './login-form.scss';

import { PatientLoginForm } from './patient-form-section';

export const LoginForm = () => {
  return (
    <main className="roche-components-login-form">
      <div className="login-content">
        <div className="site-btns container container-small container-md-full d-flex flex-column p-0 mt-5">
          <PatientLoginForm />
        </div>
      </div>
    </main>
  );
};

export default function LoginFormWrapper({ mode }) {
  return <LoginForm mode={mode} />;
}
