import React from 'react';
import './patient-form-section.scss';
import DinModal from './din-modal';
import { TranslatableText, TranslatedBlock } from './language';

class PatientFormSection extends React.Component {
  state = {
    open: false,
    dinNumber: '',
    modalOpen: false,
    toVideo: false,
    error: '',
  };

  toggleHeader = () => {
    this.setState({ open: !this.state.open });
  };

  updateInputValue = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  VALID_PASSCODES = ['02527618', 'vision', 'myvision', 'visionsupport', 'visionhelp'];

  handlePatientLogin = event => {
    event.preventDefault();
    this.setState({ error: '' });
    if (this.state.dinNumber.length > 0) {
      if (this.VALID_PASSCODES.includes(this.state.dinNumber.toLowerCase())) {
        window.location.replace('./home/');
      } else {
        this.setState({ error: 'din-number-error' });
      }
    } else {
      this.setState({ error: 'din-number-empty' });
    }
  };

  toggleDinModal = e => {
    e.preventDefault();
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    return this.props.children({
      open: this.state.open,
      modalOpen: this.state.modalOpen,
      toggleHeader: this.toggleHeader,
      toggleDinModal: this.toggleDinModal,
      handlePatientLogin: this.handlePatientLogin,
      updateInputValue: this.updateInputValue,
      error: this.state.error,
    });
  }
}

export const PatientLoginForm = ({ toggleDinModal, error }) => {
  return (
    <PatientFormSection>
      {({ open, toggleHeader, modalOpen, toggleDinModal, handlePatientLogin, updateInputValue, error }) => (
        <div className={'login-patient login-container mb-5 mt-5'}>
          <form id="main-content" className={'row xs-mt-5 xs-mb-5 p-0 p-md-5 p-lg-0'}>
            <div className={'col-12 col-lg-6 d-flex flex-column justify-content-center py-5'}>
              <TranslatedBlock language="english">
                <p className="header pl-0 pl-md-4">
                  Welcome to your online <span className="sup">ᴾʳ</span>VABYSMO<sup>®</sup> (faricimab injection)
                  information
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p className="header pl-0 pl-md-4 smaller">
                  Bienvenue au site d’information en ligne sur <span className="sup fr">ᴾʳ</span>VABYSMO
                  <sup className="fr">®</sup> (faricimab injectable)
                </p>
              </TranslatedBlock>
              <p className="paragraph pl-0 pl-md-4">
                <TranslatableText
                  dictionary={{
                    english:
                      'In order to look at this website, please enter the passcode given to you by your healthcare team, or the VABYSMO DIN (Drug Identification Number).',
                    french:
                      'Pour consulter ce site Web, veuillez entrer le code d’accès qui vous a été fourni par votre équipe soignante, ou le DIN (numéro d’identification du médicament) de VABYSMO.',
                  }}
                />
              </p>
            </div>
            <div className={'col-12 col-lg-6 d-flex flex-column justify-content-center py-5 text-white'}>
              <p className="mb-0 header">
                <TranslatableText
                  dictionary={{
                    english: 'PASSCODE* or DIN',
                    french: 'CODE D’ACCÈS* ou DIN',
                  }}
                />
              </p>
              <p className="paragraph">
                <TranslatableText
                  dictionary={{
                    english: '(Drug Identification Number)',
                    french: '(numéro d’identification du médicament)',
                  }}
                />
              </p>
              <p className="paragraph">
                <TranslatableText
                  dictionary={{
                    english: '* Please ask your healthcare team for a passcode.',
                    french: '* Veuillez demander un code d’accès à votre équipe soignante.',
                  }}
                />
              </p>
              <div className="form-group xs-pt-30">
                <div className="d-flex justify-content-center align-items-center">
                  <TranslatedBlock language="english">
                    <input
                      id="din-number"
                      name="dinNumber"
                      title="DIN Number"
                      aria-label="DIN Number"
                      className={
                        error.startsWith('din-number') ? 'form-control error ml-3 mb-3' : 'form-control ml-3 mb-3'
                      }
                      type="text"
                      placeholder="Enter passcode or DIN"
                      onChange={updateInputValue}
                    />
                  </TranslatedBlock>
                  <TranslatedBlock language="french">
                    <input
                      id="din-number"
                      name="dinNumber"
                      title="DIN"
                      aria-label="DIN"
                      className={
                        error.startsWith('din-number') ? 'form-control error ml-3 mb-3' : 'form-control ml-3 mb-3'
                      }
                      type="text"
                      placeholder="Entrez le code d’accès ou le DIN"
                      onChange={updateInputValue}
                    />
                  </TranslatedBlock>
                </div>

                <TranslatedBlock language="english">
                  <p id="din-number-error" hidden={error !== 'din-number-error'} className="text-danger m-0">
                    Wrong DIN number or passcode
                  </p>
                  <p id="din-number-empty" hidden={error !== 'din-number-empty'} className="text-danger m-0">
                    Empty DIN number or passcode
                  </p>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <p id="din-number-error" hidden={error !== 'din-number-error'} className="text-danger m-0">
                    Mauvais numéro ou code d'accès
                  </p>
                  <p id="din-number-empty" hidden={error !== 'din-number-empty'} className="text-danger m-0">
                    Entrez le numéro DIN ou code d'accès
                  </p>
                </TranslatedBlock>

                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <a className="d-block paragraph mb-0" onClick={toggleDinModal} href="#">
                  <TranslatableText
                    dictionary={{
                      english: 'Need help finding the DIN?',
                      french: 'Vous n’arrivez pas à trouver le DIN?',
                    }}
                  />
                </a>
                {/* eslint-enable jsx-a11y/anchor-is-valid */}
              </div>
              <div className="form-group xs-pt-15">
                <TranslatedBlock language="english">
                  <input
                    type="submit"
                    id="patientLogin"
                    className="btn btn-primary btn-block btn-login"
                    value="LOGIN"
                    onClick={handlePatientLogin}
                  />
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <input
                    type="submit"
                    id="patientLogin"
                    className="btn btn-primary btn-block btn-login"
                    value="Ouvrir une session"
                    onClick={handlePatientLogin}
                  />
                </TranslatedBlock>
              </div>
            </div>
          </form>
          <DinModal isOpen={modalOpen} toggle={toggleDinModal} />
        </div>
      )}
    </PatientFormSection>
  );
};
