import React from 'react';
import './din-modal.scss';

import { Button, Modal, ModalBody } from 'reactstrap';
import { TranslatedBlock, TranslatableText } from './language';

export default ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} toggle={toggle} className={'modal-lg'} id="din-modal">
    <ModalBody>
      <Button className="close" onClick={toggle} close>
        x
      </Button>
      <div className="din-information-container">
        <p className="din-information-header">
          <TranslatableText dictionary={{ english: 'DIN Information', french: 'Information sur le DIN' }} />
        </p>
        <p className="din-information-text">
          <TranslatedBlock language="english">
            The DIN is the 8-digit number appearing in the top right corner of your VABYSMO<sup>®</sup> box. If you do
            not have access to a DIN, please ask your healthcare team for a passcode.
          </TranslatedBlock>
          <TranslatedBlock language="french">
            Le DIN est le nombre à huit chiffres qui figure dans le coin supérieur droit de la boîte de VABYSMO
            <sup>®</sup>. Si vous n’avez pas accès à un DIN, veuillez demander un code d’accès à votre équipe soignante.
          </TranslatedBlock>
        </p>
      </div>
    </ModalBody>
  </Modal>
);
