import React from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import MainHeader from 'src/components/main-header';
import Footer from 'src/components/footer';
import Helmet from 'react-helmet';
import LoginForm from 'src/components/login-form';
import SSRPage from 'src/components/ssr-page';
import DinPopUpBg from 'src/images/din-popup-bg.png';

const isBrowser = typeof window !== 'undefined';

export default class LoginPage extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('login');
    this.context.setPage('login');
  }

  render() {
    if (!isBrowser) {
      return <SSRPage noindexMeta={false} />;
    }

    return (
      <>
        <AppStateContext.Consumer>
          {({ language }) => (
            <Helmet
              title={language === 'french' ? 'Bienvenue | VABYSMO' : 'Welcome | VABYSMO'}
              meta={[
                {
                  name: 'description',
                  content:
                    language === 'french'
                      ? 'VABYSMO vous a été prescrit. Consultez ce site Web pour obtenir des renseignements sur votre médicament et d’autres sujets susceptibles de vous intéresser.'
                      : 'You have been prescribed VABYSMO. Look at this website to learn more about your medicine and other useful information.',
                },
              ]}
            >
              <html lang={language === 'french' ? 'fr' : 'en'} />
              <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                data-document-language="true"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script={process.env.GATSBY_OT_SCRIPT_ID}
              ></script>
              <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

              <body />
            </Helmet>
          )}
        </AppStateContext.Consumer>
        <MainHeader showNavbar={false} />
        <img src={DinPopUpBg} alt="DinPopUpBg" className={'d-none'} />
        <LoginForm />
        <Footer />
      </>
    );
  }
}
